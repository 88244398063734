<template>
  <section class="coovally">
    <section class="banner">
      <figure class="buttons">
        <div class="button">
          <el-button
            class="unchecked"
            @click="RoutePush('/CooVallyHome')"
            style="width: 136px"
            >{{ $t("PC.Product.CooVallyButton.deaa52") }}</el-button
          >
          <el-button class="unchecked" @click="RoutePush('/CooVally')">{{
            $t("PC.Product.CooVallyButton.2446d2")
          }}</el-button>
          <el-button class="unchecked" @click="RoutePush('/AIShopNew')">{{
            $t("PC.Product.CooVallyButton.fad28a")
          }}</el-button>
          <el-button class="checked">{{
            $t("PC.Product.CooVallyButton.ed4a49")
          }}</el-button>
        </div>
      </figure>
      <figure class="firstItem">
        <div class="questionnaire" v-show="!subsuccess">
          <div class="title">
            <span>{{ $t("PC.Product.ApplyTrial.08dcb6") }}</span>
          </div>
          <div class="input item">
            <p>1、{{ $t("PC.Product.ApplyTrial.c6ebef") }}</p>
            <el-input
              v-model="name"
              :placeholder="$t('PC.Product.ApplyTrial.90d2a3')"
            ></el-input>
          </div>
          <div class="chouse item">
            <p>2、{{ $t("PC.Product.ApplyTrial.e325be") }}</p>

            <div class="sex">
              <el-radio v-model="sex" label="男">{{
                $t("PC.Product.ApplyTrial.557796")
              }}</el-radio>
              <el-radio v-model="sex" label="女">{{
                $t("PC.Product.ApplyTrial.b17493")
              }}</el-radio>
            </div>
          </div>

          <div class="input item">
            <p>3、{{ $t("PC.Product.ApplyTrial.e82c9f") }}</p>
            <el-input
              v-model="telephone"
              :placeholder="$t('PC.Product.ApplyTrial.082c13')"
            ></el-input>
          </div>

          <div class="input item">
            <p>4、{{ $t("PC.Product.ApplyTrial.b2d4c7") }}</p>
            <el-input
              v-model="email"
              :placeholder="$t('PC.Product.ApplyTrial.8eb514')"
            ></el-input>
          </div>

          <div class="input item">
            <p>5、{{ $t("PC.Product.ApplyTrial.bc4bfe") }}</p>
            <el-input
              v-model="company"
              :placeholder="$t('PC.Product.ApplyTrial.eb59ce')"
            ></el-input>
          </div>
          <div class="chouse item">
            <p>6、{{ $t("PC.Product.ApplyTrial.4922df") }}</p>
            <div class="industry">
              <el-radio v-model="industry" label="互联网/IT/电子/通信">{{
                $t("PC.Product.ApplyTrial.0b82bd")
              }}</el-radio>
              <el-radio v-model="industry" label="金融业">{{
                $t("PC.Product.ApplyTrial.023c8b")
              }}</el-radio>
              <el-radio v-model="industry" label="制造业">{{
                $t("PC.Product.ApplyTrial.6cc597")
              }}</el-radio>
              <el-radio v-model="industry" label="服务业">{{
                $t("PC.Product.ApplyTrial.e5b042")
              }}</el-radio>
              <el-radio v-model="industry" label="教育培训/科研">{{
                $t("PC.Product.ApplyTrial.0f6887")
              }}</el-radio>
              <el-radio v-model="industry" label="制药/医疗">{{
                $t("PC.Product.ApplyTrial.8167d7")
              }}</el-radio>
              <el-radio v-model="industry" label="其他行业">{{
                $t("PC.Product.ApplyTrial.c4c9b1")
              }}</el-radio>
            </div>
          </div>

          <div class="chouse item">
            <p>7、{{ $t("PC.Product.ApplyTrial.3b925f") }}</p>

            <div class="team">
              <el-radio v-model="is_team" label="1">{{
                $t("PC.Product.ApplyTrial.27e24f")
              }}</el-radio>
              <el-radio v-model="is_team" label="0">{{
                $t("PC.Product.ApplyTrial.a88965")
              }}</el-radio>
            </div>
          </div>

          <div class="apply">
            <el-button
              class="applyButton"
              :disabled="cantSub"
              :style="{ background: subbtncolor }"
              @click="subInformation()"
              >{{ $t("PC.Product.ApplyTrial.9e5e4d") }}</el-button
            >
          </div>
        </div>

        <div class="subsuccess" v-show="subsuccess">
          <div class="mainImg">
            <img :src="$t('locale') == 'zh' ? suanfas : suanfas_en" alt="" />
          </div>
          <div class="content">
            <div class="title">
              <p>{{ $t("PC.Product.ApplyTrial.bc868e") }}</p>
            </div>
            <div class="text">
              <p>{{ $t("PC.Product.ApplyTrial.06ad54") }}</p>
            </div>
            <div class="tips">
              <span v-show="$t('locale') == 'zh'">{{ times }}s </span>
              <span>{{ $t("PC.Product.ApplyTrial.8b07be") }}</span>
              <span style="margin-left: 5px" v-show="$t('locale') == 'en'">
                {{ times }}s</span
              >
            </div>
          </div>
        </div>
      </figure>

      <FooterC></FooterC>
    </section>
  </section>
</template>

<script>
import FooterC from "../components/FooterC.vue";
export default {
  name: "Home",
  components: {
    FooterC,
  },

  data() {
    return {
      date: "",
      name: "",
      sex: "男",
      telephone: "",
      email: "",
      company: "",
      industry: "互联网/IT/电子/通信",
      is_team: "1",
      cantSub: true,
      subbtncolor: "#4568EEcc",
      subsuccess: false,
      suanfas: require("../../../assets/zh/images/shiyong_img@2x.png"),
      suanfas_en: require("../../../assets/zh/images/shiyong_img@2x.png"),
      times: 10,
    };
  },

  watch: {
    name: function () {
      this.checkSub();
    },
    telephone: function () {
      this.checkSub();
    },
    email: function () {
      this.checkSub();
    },
    company: function () {
      this.checkSub();
    },
  },
  created() {},
  mounted() {
    this.date = new Date().getFullYear() + "";
  },
  methods: {
    checkSub() {
      if (
        this.name != "" &&
        this.telephone != "" &&
        this.email != "" &&
        this.company != ""
      ) {
        this.subbtncolor = "#4568EE";
        this.cantSub = false;
      } else {
        this.subbtncolor = "#4568EEcc";
        this.cantSub = true;
      }
    },

    routerGo(routeName) {
      const { href } = this.$router.resolve({
        name: routeName,
      });
      window.open(href);
    },
    newOpen(url) {
      window.open(url);
    },
    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },

    subInformation() {
      this.axios
        .post(Global.COOVALLY_URL + Global.SAVE_APPLY, {
          name: this.name,
          sex: this.sex,
          telephone: this.telephone,
          email: this.email,
          company: this.company,
          industry: this.industry,
          is_team: this.is_team,
        })
        .then((res) => {
          if (res.status == 201) {
            this.getCode();
            this.subsuccess = true;
          }
        });
    },

    getCode() {
      this.timer = setInterval(() => {
        this.times--;
        if (this.times === 1) {
          clearInterval(this.timer);
          setTimeout(() => {
            this.$router.push({
              path: "/CooVallyHome",
            });
          }, 900);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.coovally {
  margin-top: 85px;
  background: linear-gradient(
    180deg,
    #eef2fe 0%,
    #f8f9ff 58%,
    rgba(238, 242, 254, 0) 100%
  );
  word-break: break-word !important;

  //首屏
  .banner {
    position: relative;
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    margin-top: -16px;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      .button {
        display: flex;
        justify-content: space-between;
        width: 766px;
        padding-top: 48px;
        .checked {
          width: 180px;
          height: 60px;
          background-color: #4568ee;
          color: #fafcff;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .checked:hover {
          width: 180px;
          height: 60px;
          background: #4e76f7;
          // border-radius: 26px;
          opacity: 0.8;
        }

        .unchecked {
          width: 180px;
          height: 60px;
          background-color: #4568ee1a;
          color: #4568ee;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .unchecked:hover {
          width: 180px;
          height: 60px;
          background: #4568ee;
          color: #fafcff;
          // border-radius: 26px;
          // opacity: 0.8;
        }
      }
    }

    .firstItem {
      width: 100%;
      display: flex;
      justify-content: center;
      .questionnaire {
        width: 766px;
        margin-top: 54px;
        // background-color: green;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          height: 30px;
          font-size: 22px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #343e4c;
          line-height: 30px;
          margin-bottom: 8px;
        }
        .item {
          width: 766px;

          p {
            text-align: left;
            width: 238px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #343e4c;
            line-height: 25px;
            margin: 16px 0;
          }
          .el-input {
            width: 400px;
            float: left;
          }
          .sex {
            width: 200px;
            float: left;
            display: flex;
          }
          .industry {
            width: 766px;
            float: left;
            display: flex;
            flex-wrap: wrap;
            .el-radio {
              margin-bottom: 5px;
            }
          }

          .team {
            width: 200px;
            float: left;
            display: flex;
            .el-radio {
              margin-right: 80px;
            }
          }
        }
      }

      .subsuccess {
        width: 766px;
        margin-top: 54px;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .mainImg {
          margin-top: -60px;
          width: 522px;
          height: 470px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .content {
          // background-color: red;
          margin-top: -30px;
          margin-bottom: 64px;
          .title {
            // width: 88px;
            height: 30px;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #343e4c;
            line-height: 30px;
          }

          .text {
            margin: 16px 0;
            width: 622px;
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #343e4c;
            line-height: 25px;
          }

          .tips {
            // width: 149px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666660;
            line-height: 20px;
          }
        }
      }
    }

    .apply {
      margin-top: 36px;
      margin-bottom: 164px;
      .applyButton {
        width: 140px;
        height: 49px;
        background: #4568ee;
        color: #fafcff;
        border-radius: 4px;
        padding: 0 0 0 0;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 25px;
      }
      .applyButton:hover {
        width: 140px;
        height: 49px;
        background: #4568eecc;
      }
    }
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #4568ee !important;
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #4568ee !important;
    border-color: #4568ee !important;
  }
}
</style>
